* {
  margin: 0;
  padding: 0;
}

.pages {
  color: #316685;
  text-align: center;
  font-size: calc(1.5rem + 2vw);
  margin-top: 10%;
}

.landing_nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: rgba(255, 255, 255, 0);

  .nav-links {
    color: white;
  }
}

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  background-color: white !important;
  z-index: 1030;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.nav-logo {
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  width: fit-content;

  img {
    width: 140px;
  }
}

.nav-menu {
  flex-grow: 0.4;
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  margin-bottom: 0;
}

.nav-right-menu {
  align-items: flex-end;
  justify-content: flex-end;

  .nav-right-item {
    a {
      font-size: 0.8rem;
      color: black;
      font-weight: 1000;
    }
  }
}

.nav-links {
  text-decoration: none;
  padding: 2.2rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;

  a {
    &:hover {
      color: #b0b0b0;
    }
  }
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #63ACB5;
}

.nav-item .active {
  color: #63ACB5;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    padding: 0;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu .active {
    background: #1f5156;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #ffdd40;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    margin-right: 1rem;
    cursor: pointer;
    color: #ffdd40;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.navbar_more_wrapper {
  width: 100%;
  position: absolute;
  background-color: white;
  z-index: 999;
  transition: height 0.3s;
  height: 0;
  overflow: hidden;

  &.active {
    border-top: 1px solid #63ACB5;
    height: 350px;
  }

  .more_item_wrapper {
    height: 100%;
    max-width: 1500px;
    display: block;
    margin: 0 auto;
  }

}

.navbar_item_wrapper {
  height: 100%;
  padding: 2rem 0;

  .left_text_wrapper {
    text-align: center;

    span {
      &:before {
        content: '';
      }

      color: #63ACB5;
    }
  }

  .right_text_wrapper {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end;

    #nav_detail_img {
      width: 340px;
      height: 170px;
    }
    #nav_detail_hard_type{

    }
    span {
      font-weight: bold;
      font-size: 1.2rem;
    }

    img {
      width: 250px;
    }
  }

  .nav_mattress_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;

    .nav_mattress_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 0.3rem 0.3rem;

      .nav_mattress_img_wrapper {
        border: 2px solid white;

        &:hover {
          border: 2px solid #63ACB5;
        }
      }

      .nav_mattress_back {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(155, 155, 155, 0.4);

        span {
          color: white;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      span {
        color: gray;
        margin-top: 0.5rem;
        font-size: 0.7rem;
      }

    }
  }

}

.aside_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  left: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  opacity: 0;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  .aside_header {
    text-align: right;

    img {
      margin-right: 2rem;
      width: 20px;
      height: 20px;
    }
  }

  .aside_nav_link {
    flex-grow: 2;
    padding: 2rem 0;

    ul {
      li {
        font-size: 1.3em;
        font-weight: bold;
        padding: 0.5rem 0;
      }
    }
  }

  .aside_nav_sub_link {
    font-size: 1rem;
    flex-grow: 2;

    li {
      a {
        color: #727272;
      }
    }
  }
}
